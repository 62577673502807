// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-tlV8P"

const variantClassNames = {QlYLpH2Pm: "framer-v-7g0p1o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, number, showNumber, title, width, ...props}) => { return {...props, i83LM0fHv: number ?? props.i83LM0fHv, kSA3wuEjn: showNumber ?? props.kSA3wuEjn, OKr5AJd5k: link ?? props.OKr5AJd5k, wSDtEcD_o: title ?? props.wSDtEcD_o ?? "Title"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;showNumber?: boolean;number?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, wSDtEcD_o, kSA3wuEjn, i83LM0fHv, OKr5AJd5k, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "QlYLpH2Pm", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={OKr5AJd5k} motionChild nodeId={"QlYLpH2Pm"} scopeId={"ebExugia5"} smoothScroll={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-7g0p1o", className, classNames)} framer-1ljdjoh`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"QlYLpH2Pm"} ref={refBinding} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "110%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(9, 9, 9))"}}>Title</motion.p></React.Fragment>} className={"framer-xu6ifz"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"Kfo0arB2u"} style={{"--extracted-r6o4lv": "rgb(9, 9, 9)", "--framer-paragraph-spacing": "0px"}} text={wSDtEcD_o} verticalAlignment={"top"} withExternalLayout/>{kSA3wuEjn && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "9px", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "100%", "--framer-text-color": "var(--extracted-r6o4lv, rgb(9, 9, 9))"}}>Content</motion.p></React.Fragment>} className={"framer-1r3wqjw"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"To5pGHidQ"} style={{"--extracted-r6o4lv": "rgb(9, 9, 9)", "--framer-paragraph-spacing": "0px", opacity: 0.6}} text={i83LM0fHv} verticalAlignment={"top"} withExternalLayout/>)}</motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tlV8P.framer-1ljdjoh, .framer-tlV8P .framer-1ljdjoh { display: block; }", ".framer-tlV8P.framer-7g0p1o { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-tlV8P .framer-xu6ifz { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-tlV8P .framer-1r3wqjw { flex: none; height: auto; position: absolute; right: -14px; top: -1px; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tlV8P.framer-7g0p1o { gap: 0px; } .framer-tlV8P.framer-7g0p1o > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-tlV8P.framer-7g0p1o > :first-child { margin-left: 0px; } .framer-tlV8P.framer-7g0p1o > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"wSDtEcD_o":"title","kSA3wuEjn":"showNumber","i83LM0fHv":"number","OKr5AJd5k":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerebExugia5: React.ComponentType<Props> = withCSS(Component, css, "framer-tlV8P") as typeof Component;
export default FramerebExugia5;

FramerebExugia5.displayName = "Menu item";

FramerebExugia5.defaultProps = {height: 18, width: 31};

addPropertyControls(FramerebExugia5, {wSDtEcD_o: {defaultValue: "Title", displayTextArea: false, title: "Title", type: ControlType.String}, kSA3wuEjn: {defaultValue: false, title: "Show number", type: ControlType.Boolean}, i83LM0fHv: {defaultValue: "", displayTextArea: false, title: "Number", type: ControlType.String}, OKr5AJd5k: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerebExugia5, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/hyOgCu0Xnghbimh0pE8QTvtt2AU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/NeGmSOXrPBfEFIy5YZeHq17LEDA.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/oYaAX5himiTPYuN8vLWnqBbfD2s.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/lEJLP4R0yuCaMCjSXYHtJw72M.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/cRJyLNuTJR5jbyKzGi33wU9cqIQ.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/1ZFS7N918ojhhd0nQWdj3jz4w.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/A0Wcc7NgXMjUuFdquHDrIZpzZw0.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})